import Axios from 'axios'
import global from '../global'

const {theme} = global
const bunnyApiClient = Axios.create({ baseURL: theme.apiUrl })

export default {
  getFolders(folderStructure) {
    return bunnyApiClient.get('/folders/' + theme.storageApiPassword + '/' + folderStructure)
  },
  
  getRenders(folderStructure) {
    return bunnyApiClient.get('/renders/' + theme.storageApiPassword + '/' + folderStructure)
  },
  
  getCollections(libraryId, searchQuery) {
    if(searchQuery) {
      return bunnyApiClient.get('/collections/' + libraryId + '/' + searchQuery + '/' + theme.streamApiKey)
    } else {
      return bunnyApiClient.get('/collections/' + libraryId + '/' + theme.streamApiKey)
    }
  },
  
  getVideos(libraryId, collectionId) {
    return bunnyApiClient.get('/videos/' + libraryId + '/' + collectionId + '/' + theme.streamApiKey)
  }
}