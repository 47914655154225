<template>
  <div 
    class="form-check mb-2"
    :class="{
      'form-check-inline':!vertical
    }"
  >
    <input 
      class="form-check-input" 
      type="radio"  
      :checked="modelValue === value"
      :value="value"
      @change="$emit('update:modelValue', value)"
      v-bind="$attrs"
    />
    <label
      class="form-check-label"
      v-if="label"
      >{{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      required: true
    },
    vertical: {
      type: [Boolean],
      default: false
    }
  }
}
</script>