<template>

  <!-- Top navigation -->
  <div class="position-absolute v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-end v-theme-header">
          <button @click="$router.go(-1)" class="btn-close-asset"></button>
        </div>
      </div>
    </div>
  </div>

  <!-- Renders -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'renders'">    
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + currentRender.ObjectName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas'">
    <iframe :src="theme.cdnUrl + '/' + 'assets/' + assetType + '/' + viewType + '/'" :title="assetTypes[0].ObjectName" width="100%" height="100%"></iframe>
  </div>

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans'">
    <div id="adobe-dc-view"></div>
  </div>

  <OffCanvas />
</template>

<script>
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      currentAsset: null,
      folderStructure: encodeURIComponent('/yves/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null
    }
  },
  created() {
    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          ComponentName: this.componentName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          id: j++
        };
      });

      // Custom order array
      if( this.viewType === 'renders' ) {
        this.assetTypes = this.reorder(menuItems, this.theme.rendersOrder);
      } else if ( this.viewType === 'panoramas' ) {
        this.assetTypes = this.reorder(menuItems, this.theme.panoramasOrder);
      } else if ( this.viewType === 'floorplans' ) {
        this.assetTypes = this.reorder(menuItems, this.theme.floorplansOrder);

        // Assign PDF filename and display name
        let file = this.assetTypes[this.order].LinkName;
        file = file.substr(0, file.lastIndexOf(".")) + ".pdf";
        let filename = this.assetTypes[this.order].ObjectName;
        filename = filename.substr(0, filename.lastIndexOf(".")) + ".pdf";
        
        this.renderPdf(this.theme.cdnUrl, '/assets/' + this.assetType + '/' + this.viewType, file, filename);
      } else {
        this.assetTypes = menuItems
      }
      
      if(window.AdobeDC) this.pdfAPIReady = true;
    })
  },
  methods: {
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },

    renderPdf: function(cdn, path, file, filename) {
      document.addEventListener("adobe_dc_view_sdk.ready", function()
      {
        var adobeDCView = new AdobeDC.View({
          clientId: "ac43a5123a9e428c8af210bd3f3a1ac0",
          divId: "adobe-dc-view"
        })
        adobeDCView.previewFile({
          content: {
            location: {
              url: cdn + path + "/pdfs/" + file
            }
          },
          metaData: {
            fileName: filename
          }
        },
        {
          defaultViewMode: "FIT_WIDTH",
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: false,
          showDownloadPDF: false,
          showPrintPDF: false
        })
      })
    }
  },
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/main.js')
    document.head.appendChild(externalScript)
  },
  computed: {
    currentRender() {
      let tempRender = this.assetTypes;
      
      tempRender = tempRender.filter((item) => {
        return (item.id == this.order)
      })
      
      return tempRender[0];
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>