<template>
  <!-- Loading icon -->
  <img src="/img/ui-theme/loading.svg" class="loading-menu">
  <!-- End loading icon -->
  <video autoplay muted playsinline loop id="bgVideo" class="z-0">
    <source src="//cdn.metaluxe.com.au/aspire/theme/types/types/full-floor/4K-full-floor.mp4" type="video/mp4">
  </video>
</template>

<script>
export default {
  name: 'AssetTypeVideo',
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
};
</script>