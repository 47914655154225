<template>
  <router-view />
</template>

<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
  name: "MainApp",
  setup() {
    const store = useStore()

    // collect currently logged in user details
    onBeforeMount(() => {
      store.dispatch('fetchUser')
    })
  },
};
</script>

<style src="./assets/css/style.css"></style>
<style lang="scss">@import './assets/styles/global.scss';</style>
