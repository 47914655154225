<template>
  <ViewTopNav :heading="this.viewType" />
  
  <div class="position-absolute p-content z-1">
			<div class="col-12 p-0 z-1 h-100">
				<div class="row g-0 h-100">

          <div class="col-12 col-md-6 col-lg-4 m-auto" v-for="asset in assetTypes" :key="asset.guid">
						<div class="row g-0">
							<div class="col-12 position-relative z-1">

                <!-- Videos -->
                <router-link
                  v-if="viewType === 'videos' && collectionId"
                  :to="{
                    name:  'VideoView',
                    params: {
                      guid: asset.guid,
                      collectionId: asset.videoLibraryId
                    }
                  }"
                  class="v-link"
                >
                  <img :src="theme.streamCdnUrl + '/' + asset.guid + '/' + asset.thumbnailFileName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.ObjectName}}
									</div>
                </router-link>

                <!-- Renders -->
                <router-link
                  v-if="viewType === 'renders'"
                  :to="{
                    name:  'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }"
                  class="r-link"
                >
                  <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.ObjectName}}
									</div>
                </router-link>

                <!-- Panoramas -->
                <router-link
                  v-if="viewType === 'panoramas'"
                  :to="{
                    name:  'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }"
                  class="p-link"
                >
                  <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.ObjectName}}
									</div>
                </router-link>

                <!-- Floorplans -->
                <router-link
                  v-if="viewType === 'floorplans'"
                  :to="{
                    name:  'AssetViewDetail',
                    params: {
                      assetType: this.assetType,
                      viewType: this.viewType,
                      order: asset.id
                    }
                  }"
                  class="f-link"
                >
                  <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                  <div class="render-name">
										{{ asset.ObjectName}}
									</div>
                </router-link>

							</div>
						</div>
					</div>
          
      </div>
    </div>
  </div>
  
  <ViewBotNav />
  <OffCanvas />
</template>

<script>
import ViewTopNav from '@/components/ViewTopNav.vue'
import ViewBotNav from '@/components/ViewBotNav.vue'
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetView",
  props: [
    'assetType',
    'viewType',
    'lid',
    'collectionId'
  ],
  components: {
    ViewTopNav,
    ViewBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      folderStructure: encodeURIComponent('/yves/assets/' + this.assetType + '/' + this.viewType + '/'),
      videoStatus: false
    }
  },
  created() {
    if(this.viewType === 'videos') {
      BunnyNetService.getVideos(this.lid, this.collectionId)
      .then(response => {
        this.assetTypes = response.data.items
        this.videoStatus = true
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      BunnyNetService.getRenders(this.folderStructure, this.assetType)
      .then(response => {
        let j = 0;
        // Remap array
        const menuItems = response.data.map((item) => {
          return {
            Guid: item.Guid,
            ObjectName: unslugify(item.ObjectName),
            LinkName: item.ObjectName,
            Path: item.Path,
            IsDirectory: item.IsDirectory,
            StorageZoneName: item.StorageZoneName,
            id: j++
          };
        });

        // Custom order array
        if( this.viewType === 'renders' ) {
          this.assetTypes = this.reorder(menuItems, this.theme.rendersOrder);
        } else if ( this.viewType === 'panoramas' ) {
          this.assetTypes = this.reorder(menuItems, this.theme.panoramasOrder);
        } else if ( this.viewType === 'floorplans' ) {
          this.assetTypes = this.reorder(menuItems, this.theme.floorplansOrder);
        } else {
          this.assetTypes = menuItems
        }
      })
    }
  },
  methods: {
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>
