<script setup>
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import global from '../global';
  
  const register_form = ref({});
  const store = useStore();
  const {theme} = global
  
  const register = () => {
    store.dispatch('register', register_form.value);
  }

  const accessOptions = [
    { label: "Web", value: 1 },
    { label: "Gallery", value: 2 },
    { label: "Premium", value: 3 }
  ]
</script>

<template>
  <header class="header mb-auto py-3">
    <div class="container">
      <div class="row mx-auto">
        <a href="#" class="text-center"><img :src="`/img/ui-theme/yves-header-logo-rev.svg`" /></a>
      </div>
    </div>
  </header>

  <div class="container h-100">
    <div class="row mx-auto">
      <div class="col-12">
        <form class="register" @submit.prevent="register">
          <h4 class="mt-5">Register</h4>
          <p class="mb-5">Enter your details below</p>

          <div class="form-group mb-2">
            <BaseInput 
              v-model="register_form.displayName"
              label=""
              placeholder="Name"
              type="text"
            />
          </div>
          
          <div class="form-group mb-2">
            <BaseInput 
              v-model="register_form.mobile"
              label=""
              placeholder="Mobile"
              type="text"
            />
          </div>
          
          <div class="form-group mb-2">
            <BaseInput 
              v-model="register_form.email"
              label=""
              placeholder="Email address"
              type="text"
            />
          </div>
          
          <div class="form-group mb-2">
            <BaseInput 
              v-model="register_form.password"
              label=""
              placeholder="Password"
              type="password"
            />
          </div>

          <!-- <BaseRadioGroup
            v-model="register_form.assign"
            name="assign"
            :options="accessOptions"
          /> -->

          <BaseSelect
            :options="accessOptions"
            v-model="register_form.access"
            label=""
            selected=""
            selectedLabel="Select access level"
          />

          <router-link
            :to="{
              name: 'Login'
            }"
          >Already have an account?
          </router-link>
        </form>
      </div>
    </div>
  </div>

  <footer class="footer mt-auto py-3">
    <div class="container">
      <div class="row mx-auto">
        <div class="d-grid gap-2">
          <button class="btn btn-main btn-lg" @click="register">Register</button>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
  .body, p, h1, h2, h3, h4, h5, h6, input, label {
    color: #1A122D;
  }
  a, a:visited {
    color: #635AEB;
    text-decoration: none !important;
  }
  a:hover {
    color: #635AEB;
    text-decoration: none !important;
  }
  .row {
    max-width: 550px;
  }
  
  input,
  span {
    display: block;
    font-size: 16px;
    color: #000;
  }
  
  /* ------------------------------------- */
  /* input styling */
  /* ------------------------------------- */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input[type='text']:-webkit-autofill,
  input[type='text']:-webkit-autofill:hover,
  input[type='text']:-webkit-autofill:focus,
  input[type='text']:-webkit-autofill:active,
  input[type='password']:-webkit-autofill,
  input[type='password']:-webkit-autofill:hover,
  input[type='password']:-webkit-autofill:focus,
  input[type='password']:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #1e1e1e;
    /* -webkit-box-shadow: 0 0 0 30px #1e1e1e inset; */
  }
  input:-webkit-autofill:hover,
  input[type='text']:-webkit-autofill:hover,
  input[type='password']:-webkit-autofill:hover {
    -webkit-text-fill-color: #1e1e1e;
  }
  input:-webkit-autofill:focus,
  input:-webkit-autofill:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 1.0);
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.5);
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.5);
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: rgba(0, 0, 0, 0.5);
  }
  :-moz-placeholder { /* Firefox 18- */
    color: rgba(0, 0, 0, 0.5);
  }
  .form-check-input:checked {
      background-color: var(--primary-agent-color);
      border-color: var(--primary-agent-color);
  }
  /* ------------------------------------- */
  /* end input styling */
  /* ------------------------------------- */
  </style>