<template>
  <div class="form-group mb-2">
    <label v-if="label">{{ label }}</label>
    <select 
      :value="modelValue"
      class="form-select"
      v-bind="{
        ...$attrs,
        onChange: ($event) => { $emit('update:modelValue', $event.target.value) }
      }"
    >
      <option
        value=""
        :selected="selected"
        disabled="disabled"
      >{{ selectedLabel }}</option>
      <option
        v-for="option in options"
        :value="option.value"
        :key="option"
        :selected="option === modelValue"
      >{{ option.label }}</option>
    </select>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      selected: {
        type: String,
        default: ''
      },
      selectedLabel: {
        type: String,
        default: ''
      },
      modelValue: {
        type: [String, Number],
        default: ''
      },
      options: {
        type: Array,
        required: true
      }
    }
  }
  </script>