import { createApp } from 'vue'
import App from './App.vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { globalCookiesConfig } from "vue3-cookies";

import './registerServiceWorker'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.css"

const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

globalCookiesConfig({
  expireTimes: "365d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None",
});

const app = createApp(App)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )

  app.component(componentName, componentConfig.default || componentConfig)
})

app.use(globalCookiesConfig);
app.use(store)
app.use(router)
app.use(VueSweetalert2)

app.mount('#app')

import "bootstrap/dist/js/bootstrap.bundle.js"
