const Swal = require('sweetalert2')

export default {
  showAlert(txt, ico) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: ico,
      titleText: txt,
      showConfirmButton: false,
      timer: 2000
    })
  }
}