<template>
  <div class="form-check form-switch mb-2">
    <input 
      class="form-check-input" 
      type="checkbox" 
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label
      class="form-check-label"
      v-if="label"
      >{{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [Boolean],
      default: false
    }
  }
}
</script>