import { createStore } from 'vuex'
import router from '../router'
import { db, auth } from '../firebase'
import { setDoc, doc, getDoc } from 'firebase/firestore'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import SweetAlert from "@/services/SweetAlert.js";

export default createStore({
  state: {
    user: null,
    agentLogo: process.env.VUE_AGENT_LOGO
  },
  getters: {
  },
  mutations: {
    // update vuex STATE object
    SET_USER (state, user) {
      state.user = user
    },

    // clear vuex STATE object
    CLEAR_USER (state) {
      state.user = null
    }
  },
  actions: {
    // login user and set SET_USER state
    async login ({ commit }, details) {
      // deconstruct submitted from data
      const { email, password } = details

      // sign in with email and password (firebase authentication)
      try {
        // successfully sign in
        await signInWithEmailAndPassword(auth, email, password)
      } catch (error) {
        // display error
        switch(error.code) {
          case 'auth/user-not-found':
            SweetAlert.showAlert("User not found", "error")
            break
          case 'auth/wrong-password':
            SweetAlert.showAlert("Wrong password", "error")
            break
          case 'auth/missing-email':
            SweetAlert.showAlert("Email missing", "error")
            break
          default:
            SweetAlert.showAlert(error, "error")
        }
        return
      }

      // get user document from firebase database
      const userRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userRef);

      // check if document found
      if (userDoc.exists()) {
        // assign user data to currentUser STATE object
        const userData = userDoc.data();
        auth.currentUser.displayName = userData.displayName;
        auth.currentUser.access = userData.access;

        // user successfully firebase authenticated set SET_USER state
        commit('SET_USER', auth.currentUser)
        // show success alert
        SweetAlert.showAlert("Welcome " + auth.currentUser.displayName, "success")
        // redirect to dashboard
        router.push('/')
      } else {
        // show user not found alert and logout user
        SweetAlert.showAlert("No user found", "error")
        // log user out
        this.logout()
      }
    },

    // register new user using firebase authentication
    async register ({ commit }, profile) {
      // deconstruct details object
      const { displayName, email, password, access, mobile } = profile

      // create new user (email and password)
      try {
        await createUserWithEmailAndPassword(auth, email, password)
      } catch (error) {
        // show error alert based on response
        const errorCode = error.code;
        const errorMessage = error.message;
        switch(errorCode) {
          case 'auth/email-already-in-user':
            SweetAlert.showAlert("Email already in use", "error")
            break
          case 'auth/invalid-email':
            SweetAlert.showAlert("Invalid email", "error")
            break
          case 'auth/operation-not-allowed':
            SweetAlert.showAlert("Operation not allowed", "error")
            break
          case 'auth/weak-password':
            SweetAlert.showAlert("Weak password", "error")
            break
          case 'auth/email-already-in-use':
            SweetAlert.showAlert("Email already in use", "error")
            break
          default:
            SweetAlert.showAlert(errorMessage, "error")
        }
        return
      }

      // add profile information to firebase database
      try {
        // call addUser action
        const userProfile = await this.dispatch('addUser', {
          uid: auth.currentUser.uid,
          profile: profile
        })
        .then(response => {
          // add profile information to current user
          auth.currentUser.name = displayName
          // auth.currentUser.access = "web"

          // commit to SET_USER state
          commit('SET_USER', auth.currentUser)

          // show successfully alert
          SweetAlert.showAlert('Account successfully created', "success")

          // redirect to dashboard
          router.push('/')
        })
        .catch(error => {
          // show error alert based on response
          SweetAlert.showAlert(error, "error")
          // log user out
          this.dispatch('logout');
        })
      } catch (error) {
        // show error alert based on response
        SweetAlert.showAlert(error, "error")
        // log user out
        this.dispatch('logout');
      }
    },

    // add user profile to database
    async addUser( { commit }, payload) {
      // deconstruct details object
      const { displayName, access, mobile } = payload.profile

      // add new document to firebase database
      const docRef = await setDoc(doc(db, "users", payload.uid), {
        access: parseInt(access),
        displayName: displayName,
        mobile: mobile
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
    },

    // logout current user
    async logout ({ commit }) {
      // sign user out firebase authentication
      await signOut(auth)
      // clear vuex user STATE object
      commit('CLEAR_USER')
      // show logged out alert
      SweetAlert.showAlert('Successfully logged out', "success")
      // redirect to login page
      router.push('/login')
    },

    // collect details of current user and commit SET_USER state
    fetchUser ({ commit }) {
      auth.onAuthStateChanged(async user => {
        if (user === null) {
          // reset vuex user STATE
          commit('CLEAR_USER')
        } else {
          // get user document from firebase database
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          // check if document found
          if (docSnap.exists()) {
            // assign user data to currentUser STATE object
            const data = docSnap.data();
            user.displayName = data.displayName;
            user.access = data.access;
            user.mobile = data.mobile;
          } else {
            // show user not found alert and logout user
            SweetAlert.showAlert("No user found", "error")
            // log user out
            this.dispatch('logout');
          }

          // commit profile / user info to SET_USER state
          commit('SET_USER', user)

          // user successfully firebase authenticated redirect to dashboard
          if (router.isReady() && router.currentRoute.value.path === '/login') {
            router.push('/')
          }
        }
      })
    }
  }
})
