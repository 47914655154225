import { reactive } from "vue";

const theme = reactive({
  agentLogo: "hirsch-faigen-footer-logo.svg",
  devLogo: "yves-header-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  streamToken: "19cc5fa9-7f50-4df9-b87f-499bf2a8aa33",
  videoLibraryId: "57915",
  collectionId: "7f127b7b-1db8-4ffa-9319-4672ba5cfef9",
  streamCdnUrl: "https://vz-8ae9ea3c-614.b-cdn.net",
  streamApiKey: "4f95df55-bb33-4fa2-885f96b4f4c8-3602-4d16",
  storageApiPassword: "194cf990-6d74-4874-99e526dca94b-4281-48c0",
  cdnUrl: "https://metadev.metaluxe.com.au",
  devFolder: "/metadev",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  dashboardWebm: "theme/dashboard/4K_dashboard.webm",
  assetsMp4: "theme/dashboard/4K_assets.mp4",
  assetsWebm: "theme/dashboard/4K_assets.webm",
  theme: [
    {
      primaryBgColor: '#1A122D',
      secondaryBgColor: '#FFFFFF',
      accentBgColor: '#000000'
    }
  ],
  assetsOrder: [
    'penthouse',
    'sub-penthouse',
    'exterior',
    '3-bedroom',
    'amenities',
    'balcony-views'
  ],
  assetTypesOrder: [
    'videos',
    'renders',
    'panoramas',
    'floorplans'
  ],
  videosOrder: [ 0 ],
  rendersOrder: [ 1, 0, 6, 3, 5, 4, 2 ],
  floorplansOrder: [ 1, 0 ],
  panoramasOrder: [ 0 ],

  assets: [
    {
      title: 'penthouse',
      types: [
        {
          title: 'videos',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'renders',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'panoramas',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'floorplans',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        }
      ]
    },
    {
      title: 'sub-penthouse',
      types: [
        {
          title: 'videos',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'renders',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'panoramas',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'floorplans',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        }
      ]
    },
    {
      title: 'exterior',
      types: [
        {
          title: 'videos',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'renders',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'panoramas',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'floorplans',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        }
      ]
    },
    {
      title: '3-bedroom',
      types: [
        {
          title: 'videos',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'renders',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'panoramas',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'floorplans',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        }
      ]
    },
    {
      title: 'amenities',
      types: [
        {
          title: 'videos',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'renders',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'panoramas',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'floorplans',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        }
      ]
    },
    {
      title: 'balcony-views',
      types: [
        {
          title: 'videos',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'renders',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'panoramas',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        },
        {
          title: 'floorplans',
          names: [
            'Main bedroom',
            'Balcony lounge',
            'Dining room',
            'Outdoor dining',
            'Pool',
            'Gym',
            'Gym'
          ]
        }
      ]
    }
  ]
});

export default { theme };