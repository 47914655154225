<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header position-fixed">
      <h5 id="offcanvasRightLabel"></h5>
      <button type="button" class="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row h-100 align-content-center">
        <div class="col-12 h-50 d-flex justify-content-center align-items-center flex-column">
          <div class="row">
            <div class="col-6 text-center m-auto pb-3">
              <img :src="`/img/ui-theme/alex-jokovich.jpg`" :alt="store.state.user.displayName" class="rounded-circle img-fluid" v-if="store.state.user" />
            </div>
            <div class="col-12 text-center">
              <h4 class="text-white" v-if="store.state.user">{{ store.state.user.displayName }}</h4>
            </div>
            <div class="col-12 text-center">
              <h6 class="text-white" v-if="store.state.user">{{ accessDisplay }} app package</h6>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-6 col-sm-4 m-auto d-flex justify-content-evenly">
              <a :href="'mailto:' + store.state.user.email" v-if="store.state.user"><img :src="`/img/ui-theme/email-icon.svg`" alt="Email"></a>
              <a :href="'tel:' + store.state.user.mobile" v-if="store.state.user"><img :src="`/img/ui-theme/phone-icon.svg`" alt="Contact"></a>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-6 col-sm-4 m-auto d-flex justify-content-evenly">
              <a @click="store.dispatch('logout')" class="btn btn-danger" data-bs-dismiss="offcanvas" aria-label="Close">Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  heading: String
})

const store = useStore()

let accessDisplay = null
switch(store.state.user.access) {
  case 1:
    accessDisplay = "Web"
    break;
  case 2:
    accessDisplay = "Gallery"
    break;
  case 3:
    accessDisplay = "Premium"
    break;
  default:
    accessDisplay = "Web"
    break;
}

onMounted(() => {
  store.dispatch('fetchUser')
})
</script>